import {DOCUMENT} from "@angular/common";
import {Inject, Injectable} from "@angular/core";

export abstract class IPageTracker {
  abstract page(obj: PageTrackerEvent);

  abstract track(actionName, properties);

  abstract trackForm(form, actionName, properties);
  abstract reset();
}
export class PageTrackerEvent {
  Path: string;
  Referrer: string;
  Search: string;
  Url: string;
}

@Injectable({
  providedIn: "root",
})
export class IntercomPageTracker implements IPageTracker {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private _window: any;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this._window = this.document.defaultView;
  }

  page(obj: PageTrackerEvent) {
    // this._window.Intercom("trackEvent", `Nav: ${obj.Url}`, obj);
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  track(actionName: any, properties: any) {
    // this._window.Intercom("trackEvent", actionName, properties);
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  trackForm(form: any, actionName: any, properties: any) {
    // this._window.Intercom("trackEvent", actionName, properties);
  }
  reset() {}
}
