import {Injectable, Inject} from "@angular/core";
import {User} from "@shared/user/user.interface";
import {Router, NavigationEnd} from "@angular/router";
import {filter} from "rxjs/operators";
import {DOCUMENT} from "@angular/common";
import {environment} from "@environments/environment";
import * as FullStory from "@fullstory/browser";
import {AuthenticationService} from "@shared/auth/authentication.service";
import {IChatBox} from "../chat/chatbox.interface";
import {IPageTracker} from "../chat/IPageTracker";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  private _window: any;

  private fullStoryInitialized = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    private chatbox: IChatBox,
    private pagetracker: IPageTracker,
  ) {
    this._window = this.document.defaultView;
  }

  initialize() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.pagetracker.page({
        Path: this.document.location.pathname,
        Referrer: this.document.referrer,
        Search: document.location.search,
        Url: this.document.location.href,
      });
    });

    this.subscribeToUserServiceObservable();
  }

  identify(user: User) {
    this.chatbox.identify(user.Id, {
      firstName: user.FirstName,
      lastName: user.LastName,
      email: user.email,
      plan: user.PlanType,
      hasTrialed: user.HasTrialed,
      subscriptionState: user.SubscriptionState,
      emailConfirmed: user.EmailConfirmed,
      recordSession: user.RecordSession,
      identifier_hash: user.user_hash,
      accountId: user.AccountId,
    });
  }

  track(action: string, properties: any = {}) {
    this.pagetracker.track(action, properties);
  }

  trackForm(form: any, action: string, properties: any = {}) {
    this.pagetracker.trackForm(form, action, properties);
  }

  startRecording(user = null) {
    this.initializeFullStory();

    FullStory.restart();

    if (user != null) {
      FullStory.identify(user.Id, {
        displayName: user.name,
        email: user.email,
        plan: user.PlanType,
        hasTrialed: user.HasTrialed,
        subscriptionState: user.SubscriptionState,
      });
    }
  }

  stopRecordingSession() {
    if (this.fullStoryInitialized) {
      FullStory.shutdown();
    }
  }

  reset() {
    this.pagetracker.reset();
    this.stopRecordingSession();
    setTimeout(() => this.chatbox.shutdown(), 4000);
  }

  subscribeToUserServiceObservable() {
    this.authenticationService.onUserLoggedIn.subscribe((user: User) => {
      if (user == null || user.SupportSessionId != null) {
        this.reset();
        return;
      }

      this.identify(user);

      if (!user?.RecordSession) {
        this.stopRecordingSession();
      } else {
        this.startRecording(user);
      }
    });
  }

  initializeFullStory() {
    if (!this.fullStoryInitialized) {
      FullStory.init({
        orgId: environment.fullstoryOrgId,
        host: environment.fullstoryHost,
        script: environment.fullstoryScript,
        devMode: !environment.production,
      });

      this.fullStoryInitialized = true;
    }
  }
}
