import {DOCUMENT} from "@angular/common";
import {Inject, Injectable} from "@angular/core";
import {CookieService} from "ngx-cookie-service";

export abstract class IChatBox {
  abstract identify(id: string, attributes: ChatboxAttributes);

  abstract showChatBox();

  abstract hideChatBox();

  abstract requestWithText(text: string);

  abstract requestChannel();

  abstract shutdown();

  abstract update();
}

export interface ChatboxAttributes {
  firstName: string;
  lastName: string;
  email: string;
  plan: string;
  hasTrialed: boolean;
  subscriptionState: string;
  emailConfirmed: boolean;
  recordSession: boolean;
  identifier_hash: string;
  accountId: string;
}

@Injectable({
  providedIn: "root",
})
export class IntercomChatBox implements IChatBox {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private _window: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cookieService: CookieService,
  ) {
    this._window = this.document.defaultView;
  }
  update() {
    // this._window.Intercom("update");
  }

  // id: string, attributes: ChatboxAttributes
  identify() {
    // this._window.Intercom("boot", {...attributes, user_hash: attributes.identifier_hash, user_id: id});
  }
  showChatBox() {
    // const elements = [
    //   this.document.getElementsByClassName("intercom-lightweight-app")?.item(0),
    //   this.document.getElementById("intercom-container"),
    // ];
    //
    // elements.forEach((element) => {
    //   if (element != null) element.classList.remove("hidden");
    // });
  }
  hideChatBox() {
    // const elements = [
    //   this.document.getElementsByClassName("intercom-lightweight-app")?.item(0),
    //   this.document.getElementById("intercom-container"),
    // ];
    //
    // elements.forEach((element) => {
    //   if (element != null) element.classList.add("hidden");
    // });
  }
  // text: string
  requestWithText() {
    // this._window.Intercom("showNewMessage", text);
  }
  requestChannel() {
    // this._window.Intercom("showNewMessage", "I would like to request support for: ");
  }
  shutdown() {
    // this._window.Intercom("shutdown");

    const cookies = this.cookieService.getAll();

    for (const cookie in cookies) {
      // if (cookie.startsWith("intercom")) {
      //   //This logs will only happen to SW admins.
      //   console.log(`[shutdown] Found an intercom cookie name: ${cookie}, removing it`);
      //   this.cookieService.delete(cookie, "/", null, false, "Lax");
      //   localStorage.removeItem("intercom.intercom-state");
      //   const cookieStillExists = this.cookieService.get(cookie);
      //   console.log("[shutdown] Checking if cookie still exists", cookieStillExists);
      // }
    }

    this.update();
  }
}
